@font-face {
    font-family: 'OpenSans';
    src: url('../fonts/open_sans/static/OpenSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'OpenSans';
    src: url('../fonts/open_sans/static/OpenSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'OpenSans';
    src: url('../fonts/open_sans/static/OpenSans-Italic.ttf');
    font-weight: normal;
    font-style: italic;
  }