.button-action {
    background-color: #F3F3F3 !important;
    border: 1px solid #F3F3F3;
    border-radius: 5px;
    color: #0D47A1;
}
  
::ng-deep .button-action:hover {
    border: 1px solid #e35a28;
    background-color: #e35a28 !important;
    color: white !important;
}

/* ADD Button */
#add {
    margin-right: 5px;
}

#add:hover {
    color: white;
}

#add-cancel:hover {
    background-color: darkgray;
    color: white;
}


/* UPDATE Button */
#update {
    margin-right:5px;
}
  
#update:hover {
    color: white;
}

#update-cancel:hover {
  background-color: darkgray;
  color: white;
}