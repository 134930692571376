.menu-sub-content ul {
    list-style-type: none;
    display: flex;
    justify-content: center;
    margin: 5px;
    padding: 0;
}

.menu-sub-content ul li {
    border-right: 1px solid #FF6D3F;
    margin-right: 5px;
    padding-right: 5px;
    text-transform: capitalize;
}

.menu-sub-content ul li:last-child {
    border: none;
    margin-right: 0;
    padding: 0;
}