.compact-menu-nav-ul {
    list-style-type: none;
    padding: 0;
    width: 89%;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.compact-menu-nav-ul li {
    font-size: normal;
    text-align: right;;
    width: 100%;
}

.compact-menu-nav-ul li div {
    background-color: white;
    border-left: 1px solid darkblue;
    border-right: 1px solid darkblue;
    color: black;
    margin-left: auto;
    padding: 5px 10px;
    text-align: right;
    text-decoration: none;
    text-transform: capitalize;
    width: 50%;
}

.compact-menu-nav-ul li:first-child div {
    border-radius: 10px 10px 0 0;
    border-top: 1px solid darkblue;
}

.compact-menu-nav-ul li:last-child div {
    border-radius: 0 0 10px 10px;
    border-bottom: 1px solid darkblue;

}


.compact-menu-nav-content {
    display: none;
    left: 0;
    position: fixed;
    text-align: center;
    top: 10px;
    width: 100%;
    z-index: 999;

}

@media (max-width: 279px) {
    .compact-menu-nav-content {
        display: none;
    }
    
    .compact-menu-nav-ul {
        width: 86%;
    }
}

@media (min-width: 280px) and (max-width: 359px) {
    .compact-menu-nav-content {
        display: none;
    }
    
    .compact-menu-nav-ul {
        width: 86%;
    }

    .compact-menu-nav-ul li div {
        width: 70%;
    }}

@media (min-width: 360px) and (max-width: 374px) {
    .compact-menu-nav-content {
        display: none;
    }
    
    .compact-menu-nav-ul {
        width: 89%;
    }
}

@media (min-width: 375px) and (max-width: 539px) {
    .compact-menu-nav-content {
        display: none;
    }

    .compact-menu-nav-ul {
        width: 89%;
    }
}

@media (min-width: 540px) and(max-width: 600px) {
    .compact-menu-nav-content {
        display: none;
    }

    .compact-menu-nav-ul {
        width: 94%;
    }
}




