/* today/src/app/pages/address/address-edit/address-edit.component.css */
fieldset {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 20px;
}
/* Center content */
form {
    max-width: 100%;
    background: #ffffff;
    width: 100%;
}

/* Form labels */
label {
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
    color: #003366;
}

legend {
    font-weight: bold;
    color: #003366;
}

/* Form fields should not exceed container width */
input, select, textarea {
    width: calc(100% - 20px); /* Subtracts padding to maintain proper spacing */
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    font-family: inherit;
    display: block;  /* Ensures full width respect */
}

/* Ensure textareas are resizable but still visually aligned */
textarea {
    resize: vertical;
    min-height: 100px;
}

/* Consistent styling for select dropdowns */
select {
    appearance: none;
    background-color: #ffffff;
    cursor: pointer;
}

/* Button styling */
button.form {
    background-color: #003366;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.3s ease-in-out;
}

button:hover {
    background-color: #00509e;
}

/* Page title */
h2 {
    text-align: center;
    text-transform: capitalize;
    color: #003366;
}

/* Checkbox alignment */
input[type="checkbox"] {
    width: auto;
    margin-left: 5px;
}

/* Responsive design */
@media (max-width: 600px) {
    form {
        max-width: 100%;
    }
}
