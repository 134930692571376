table {
  width: 100%;
  border-collapse: collapse;
  margin: 0;
  font-size: 0.85rem;
  font-family: Roboto, "Helvetica Neue", sans-serif;

  thead {
    background-color: #f0f0f0;
    color: black;

    th {
      padding: 5px;
      font-weight: normal !important;
      text-align: left;
      border-bottom: 1px solid #e35a28;
      border-top: 1px solid lightgray;
    }

    // Align the header of the actions column to the right.
    th:last-child {
      text-align: right;
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid #ddd;

      &:hover {
        background-color: #f9f9f9;
      }

      td {
        padding: 4px;
        text-align: left;
      }

      // Align the cell in the actions column to the right.
      td:last-child {
        text-align: right;
      }

      &:nth-child(even) {
        background-color: #fafafa;
      }
    }
  }

  tfoot {
    background-color: #f0f0f0;
    td {
      padding: 3px;
      font-weight: 500;
    }
  }
}
