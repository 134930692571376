/* 
MAIN MENU
.menu-nav-main-ul {
    list-style-type: none;
    margin: 5px;
    display: flex;
    justify-content: center;
}


.menu-nav-main-ul li {
    border-right: 1px dashed #FF6D3F;
    font-size: medium;
    padding: 0 5px 0 5px;
    text-transform: capitalize;
}
        */


/*
.menu-nav-main-ul li:last-child {
    border-right: 0;
}

.menu-nav-main-ul li a {
    text-decoration: none;
}

.menu-nav-main-ul li:last-child {
    margin-right: 0;
}

.menu-nav-main-content {
    text-align: center;
    width: 100%;
    display: inline;
}

.menu-nav-main-selected {
    font-weight: bold;
    text-decoration: underline; 
}
*/





