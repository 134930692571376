.filter {
    border: 1px solid #ccc;
    border-radius: 4px;
    display: block;
    font-family: inherit;
    font-size: 10pt;
    margin: 0;
    padding: 5px;
}

.filter-select {
    border: 1px solid #ccc;
    border-radius: 4px;
    display: block;
    font-family: inherit;
    font-size: 10pt;
    margin: 0;
    min-width: 50px;
    padding: 5px;

}