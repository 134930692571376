@use '@angular/material' as mat;

/* Define a universal theme */
$my-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette(mat.$indigo-palette),
    accent: mat.define-palette(mat.$pink-palette),
    warn: mat.define-palette(mat.$red-palette),
  ),
  typography: mat.define-typography-config(),
  density: 0
));

/* Apply the theme globally */
@include mat.all-component-themes($my-theme);


/* today/src/styles.scss */

@import "primeicons/primeicons.css";
@import './assets/css/button.scss';
@import url('./assets/css/fonts.css');
@import './assets/css/filter.scss';
@import url('./assets/css/form.css');
@import url('./assets/css/menu.css');
@import url('./assets/css/menu-compact.css');
@import url('./assets/css/menu-sub.scss');
@import './assets/css/table.scss';

h3 {
    color: navy;
    text-transform: capitalize;
}

.p-link {
    border-radius: 4px;
    border: 1px solid lightgray
}
.p-link:hover {
    background-color: #FF6A3C;
    border-radius: 4px;
    border: 1px solid lightgray;
    color: white;
}
.p-datepicker-current-day {
    background-color: #FF6A3C;
    color: white;
}
.p-datepicker table td.p-datepicker-today > span {
    background-color: lightblue;;
}

body {
    /* 
    font-family: 'OpenSans', sans-serif;
    */
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif; 
}

html, body { height: 100%; }

.content {
    width: 100%;
    padding: 0 16px;  /* small boundary on the left and right */
    box-sizing: border-box;
}

.content-container {
    width: 100%;
    padding: 1% 1%;  /* small boundary on the left and right */
    box-sizing: border-box;  /* ensures padding is included in the width */
}

.router-wrapper {
    display: flex;
    justify-content: center;  /* Center horizontally */
    align-items: center;      /* Center vertically */
    min-height: calc(100vh - 100px); /* Adjust based on header/footer height */
    padding: 20px; /* Prevent content from touching the edges */
}


.mat-mdc-tooltip {
    text-transform: capitalize !important;
}

@media (max-width: 600px) {


    :host ::ng-deep .p-menubar .p-submenu-list {
        z-index: 99999; 
    }     
}

/* For screens wider than 599px */
@media (min-width: 600px) {
    .content-container {
        max-width: 1200px;
        margin: 1% auto;
        padding: 0;
    }
}


